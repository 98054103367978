import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Link } from 'gatsby'
import React, { Fragment, useState } from 'react'

const ArtItem = (props) => {
  const theme = useTheme()
  const { data } = props
  const [loaded, setLoaded] = useState(false)
  const [errored, setErrored] = useState(false)
  return (
    <div className="w-full relative mb-2 cursor-pointer animate-fadeIn">
      <Link to={`/galleri/${data.node.slug}/`}>
        {data.node.featuredImage ? (
          <Fragment>
            {!loaded ? (
              <div
                style={{
                  paddingTop:
                    data.node.featuredImage.node.mediaDetails.height &&
                    data.node.featuredImage.node.mediaDetails.width
                      ? (data.node.featuredImage.node.mediaDetails.height /
                          data.node.featuredImage.node.mediaDetails.width) *
                          100 +
                        '%'
                      : '150%',
                  backgroundColor: theme?.palette.primary.light,
                }}
                className={`w-full animate-pulse`}
              ></div>
            ) : null}

            <img
              className={`${loaded ? '' : 'w-1 h-1'} ${
                !errored ? '' : 'py-12 px-4 bg-red-200'
              } w-full`}
              srcSet={data.node.featuredImage.node.srcSet}
              alt={data.node.title}
              onLoad={() => {
                setLoaded(true)
              }}
              onError={() => {
                console.log('I Errored')
                setLoaded(true)
                setErrored(true)
              }}
            />
          </Fragment>
        ) : (
          <div>
            <span className="block w-full">No image</span>
            <pre style={{ textAlign: 'left', fontSize: '10px' }}>
              {JSON.stringify(data.node, null, 2)}
            </pre>
          </div>
        )}
      </Link>
      <Link
        to={`/galleri/${data.node.slug}/`}
        style={{ backgroundColor: 'rgba(0,0,0,.6)' }}
        className={`absolute h-full w-full px-2 transition duration-200 opacity-0 hover:opacity-100 z-10 top-0 left-0 flex items-center justify-center`}
      >
        <Typography className="text-left text-white">
          {data.node.title}
        </Typography>
      </Link>
    </div>
  )
}

export default ArtItem
